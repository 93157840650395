import React from 'react'
import { Link } from 'gatsby'
// import Img from 'gatsby-image'

import Layout from '../components/layout'

const SuccessPage = () => (
  <Layout>
    <div className="form-submission">
      <div className="contentArea">
        <h1>Thank you for your order!</h1>
        <p>Your support helps drive this team and keeps the show going!</p>
        <p>Return <Link to ="/">back to homepage</Link> or checkout our other links and social profiles in the footer.</p>
      </div>
    </div>
  </Layout>
)

export default SuccessPage
